/*
    DEMO STYLE
*/

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
body {
    font-family: 'Poppins', sans-serif;
    background: #e4e3e3;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 40px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    perspective: 1500px;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #7e0202;
    color: #fff;
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    transform-origin: bottom left;
}

#sidebar.active {
    margin-left: -250px;
    transform: rotateY(100deg);
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #7e0202;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #570202;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #7e0202;
    background: #fff;
}

#sidebar ul li.active>a, a[aria-expanded="true"] {
    color: #fff;
    background: #700909;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #7e0202;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7e0202;
}

a.article, a.article:hover {
    background: #7e0202 !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

#sidebarCollapse {
    width: 40px;
    height: 40px;
    background: rgb(124, 3, 3);
    border-radius: 7px;
    cursor: pointer;
}

#sidebarCollapse span {
    width: 70%;
    height: 2.5px;
    margin: 0 auto;
    display: block;
    background: white;
    transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
    transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
}

#sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
}

#sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
}

div.versao {
    font-size: 15px;
    border-radius: 7px;
    padding: 5%;
    background: #a70404 !important;
    color: #fff !important;
}

.bgAncora {
    background-color: #700909;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
        transform: rotateY(90deg);
    }
    #sidebar.active {
        margin-left: 0;
        transform: none;
    }
    #sidebarCollapse span:first-of-type, #sidebarCollapse span:nth-of-type(2), #sidebarCollapse span:last-of-type {
        transform: none;
        opacity: 1;
        margin: 5px auto;
    }
    #sidebarCollapse.active span {
        margin: 0 auto;
    }
    #sidebarCollapse.active span:first-of-type {
        transform: rotate(45deg) translate(2px, 2px);
    }
    #sidebarCollapse.active span:nth-of-type(2) {
        opacity: 0;
    }
    #sidebarCollapse.active span:last-of-type {
        transform: rotate(-45deg) translate(1px, -1px);
    }
}

.spanBtn {
    display: flex;
    justify-content: center;
    padding-left: 3px;
    padding-top: 7px;
    font-size: 23px;
    text-decoration: none;
}

.stileBtn {
   
    position: fixed;
    background-color: #700909;
    border-radius: 16px;
    bottom: 14px;
    box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.75);
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: 50px;
    right: 22px;
    width: 50px;
    z-index : 3;
    
 }

 .stileBtn:hover {
     background-color: #ffffff;
     color: #700909;
 } 