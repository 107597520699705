* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
  min-height: auto;
}